<template>
  <div class="pa-8">
    <div class="tableHeader">
      <p>{{ $vuetify.lang.t("$vuetify.email_templates") }}</p>
      <v-btn color="secondary" large @click="dialog = true">
        {{ $vuetify.lang.t("$vuetify.create_new_templates") }}
      </v-btn>
    </div>

    <loader :loading="loading">
      <v-row row class="bordered pa-0 ma-0 template-container">
        <v-col cols="3" class="p-0">
          <v-card color="grey lighten-4 elevation-0" elevaton="0">
            <!-- <v-card-title class="tableHeader"> Email Templates </v-card-title> -->
            <v-list class="grey lighten-4 pt-0">
              <template v-for="(item, index) in templates">
                <v-list-item :key="item.subject" @click="selected = item">
                  <v-list-item-content>
                    <v-list-item-title
                      :class="{
                        selected_active: item.subject == selected.subject,
                      }"
                      v-html="item.subject"
                    ></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  :key="index"
                  v-if="index !== templates.length - 1"
                ></v-divider>
              </template>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-layout
            v-if="selected"
            class="text-xs-left my-3 px-3 bordered-bottom pb-3"
            row
            justify-space-around
            align-center
          >
            <v-flex xs12 sm7>
              <h5
                class="subheading selected_active"
                v-html="selected.subject"
              ></h5>
              <span class="body-2 grey--text" v-if="selected.updated_at"
                >Last Modified On: {{ selected.updated_at | date("lll") }}</span
              >
            </v-flex>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="blue lighten-2 "
              class="mx-3"
              @click="editTemplate"
            >
              {{ $vuetify.lang.t("$vuetify.edit") }}
            </v-btn>
            <v-btn
              outlined
              color="red darken-2"
              @click="deleteTemplate"
              :loading="deleteLoading"
              >{{ $vuetify.lang.t("$vuetify.delete") }}</v-btn
            >
          </v-layout>
          <v-layout
            v-if="selected"
            row
            align-center
            class="text-xs-left px-4 py-2"
          >
            <p class="body-1" v-html="selected.message"></p>
          </v-layout>
        </v-col>
      </v-row>
    </loader>
    <!-- notification snackbar -->
    <v-snackbar :timeout="4000" :color="color" v-model="snackbar">
      {{ message }}
      <v-btn dark @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <!-- dialog -->
    <v-dialog v-model="dialog" max-width="600px">
      <AddTemplate
        :key="dialog"
        :edit="edit"
        :selectedTemplate="selected"
        @refresh="getTemplates"
        @close-model="closeModel"
      />
    </v-dialog>
  </div>
</template>

<script>
import AddTemplate from "@/components/templates/add-templates";

import { mapState } from "vuex";
export default {
  name: "setting-templates",
  components: {
    AddTemplate,
  },
  data() {
    return {
      templates: [],
      selected: null,
      loading: false,
      deleteLoading: false,
      // ----------
      dialog: false,
      edit: false,
      // -------
      snackbar: false,
      color: "",
      message: "",
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.getTemplates();
  },
  methods: {
    async getTemplates() {
      try {
        this.loading = true;
        const resp = await this.axios.get(
          `/templates?company_id=${this.user.company_id}`
        );
        this.templates = resp.data.data;
        if (!this.selected) this.selected = this.templates[0];

        this.loading = false;
      } catch (error) {
        this.loading = false;
        alert("something went wrong");
      }
    },
    async deleteTemplate() {
      try {
        this.deleteLoading = true;
        await this.axios.delete(`/templates/${this.selected._id}`);
        const index = this.templates.findIndex(
          (template) => template._id === this.selected._id
        );
        if (index !== -1) this.templates.splice(index, 1);
        this.selected = this.templates[0];
        this.color = "success";
        this.message = "Template deleted successfully!";
        this.snackbar = true;
        this.deleteLoading = false;
      } catch (error) {
        this.deleteLoading = false;
        console.log(error);
      }
    },
    editTemplate() {
      this.edit = true;
      this.dialog = true;
    },
    closeModel() {
      this.dialog = false;
      this.edit = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
  padding: 15px !important;
}

.bordered {
  border: 1px solid #ddd;
}
.bordered-bottom {
  border-bottom: 1px solid #ddd;
}

.template-container {
  min-height: 70%;
}

.selected_active {
  font-weight: bold;
}

.tableHeader {
  letter-spacing: 1px;
  font-weight: bold;
  background-color: #eaeef2;
  border: 1px solid #e6e6f1;
  color: #3dade5;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > p {
    margin: 0;
  }
}
</style>
