<template>
  <v-card :loading="loading">
    <v-card-title>
      {{ $vuetify.lang.t(`$vuetify.${edit ? "edit" : "add"}`) }}
      {{ $vuetify.lang.t("$vuetify.template") }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid">
        <v-text-field
          required
          v-model="template.subject"
          :label="$vuetify.lang.t('$vuetify.subject')"
        ></v-text-field>

        <vue-html5-editor
          v-if="template"
          @change="(e) => (template.message = e)"
          :content="template.message"
          :height="500"
        ></vue-html5-editor>

        <div class="mt-5 d-flex align-items-center justify-content-between">
          <v-btn
            :loading="loading"
            large
            dark
            color="secondary"
            @click="submit"
          >
            {{ $vuetify.lang.t(`$vuetify.${edit ? "update" : "create"}`) }}
          </v-btn>

          <v-btn large outlined color="blue" @click="cancel">
            {{ $vuetify.lang.t("$vuetify.cancel") }}
          </v-btn>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "add-templates",
  props: {
    edit: { type: Boolean, default: false },
    selectedTemplate: { type: Object },
  },
  data() {
    return {
      valid: false,
      loading: false,
      template: {
        message: "",
        subject: "",
        company_id: "",
      },
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    if (this.edit) this.template = this.selectedTemplate;
  },
  methods: {
    async submit() {
      if (this.valid && this.template.message) {
        this.loading = true;
        try {
          if (this.edit) await this.editTemplate();
          else await this.createTemplate();
          this.$emit("refresh");
          this.cancel();
        } catch (error) {
          console.log(error);
        }
      }
    },
    editTemplate() {
      return new Promise((resolve, reject) => {
        this.axios
          .put(`/templates/${this.template._id}`, this.template)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => reject(err));
      });
    },
    createTemplate() {
      return new Promise((resolve, reject) => {
        this.template.company_id = this.user.company_id;
        this.axios
          .post("/templates", this.template)
          .then((resp) => resolve(resp))
          .catch((err) => reject(err));
      });
    },
    cancel() {
      this.loading = false;
      this.$emit("close-model");
    },
  },
};
</script>

<style lang="scss" scoped></style>
